import * as React from 'react'

type TradingHoursProps = {
    monday?: string,
    tuesday?: string,
    wednesday?: string,
    thursday?: string,
    friday?: string,
    saturday?: string,
    sunday?: string
}

class TradingHours extends React.Component<TradingHoursProps> {
    render() {
        return <>
            <style>{`
                p.opening-hours span.day {
                    width: 2.4em;
                    display: inline-block;
                }
            `}
            </style>

            <p className="opening-hours">
                {this.getItem("Mon", this.props.monday)}<br/>
                {this.getItem("Tue", this.props.tuesday)}<br/>
                {this.getItem("Wed", this.props.wednesday)}<br/>
                {this.getItem("Thu", this.props.thursday)}<br/>
                {this.getItem("Fri", this.props.friday)}<br/>
                {this.getItem("Sat", this.props.saturday)}<br/>
                {this.getItem("Sun", this.props.sunday)}
            </p>
        </>;
    }

    getItem(name: string, hours?: string) {
        return <>
            <span className="day">{name}:</span> <strong>{hours || "Closed"}</strong>
        </>;
    }
}

export default class Footer extends React.Component {
    render() {
        // HACK
        const worldMap = require('../Style/images/world-map.png');

        return (
            <>
                <footer className="footer bg-light pt-6">
                    <div className="footer-content pb-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4"/>
                                <div className="col-md-4 col-sm-6">
                                    <div className="widget address" style={{
                                        backgroundImage: 'url(' + worldMap + ')',
                                        backgroundPosition: '50% 20px',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain'
                                    }}>
                                        <ul className="list-unstyled">
                                            <li className="media mb-3">
                                                <i className="mr-3 display-8 ti-map-alt"></i>
                                                Shop 2, 57 James Street<br/>
                                                Burleigh Heads QLD 4220
                                            </li>

                                            <li className="media mb-3">
                                                <i className="mr-3 display-8 ti-email"></i>
                                                <a href="mailto:info@burleighoptom.com.au">info@burleighoptom.com.au</a>
                                            </li>

                                            <li className="media mb-3">
                                                <i className="mr-3 display-8 ti-mobile"></i> (07) 5535 5999
                                            </li>

                                            <li className="media mb-3">
                                                <i className="mr-3 display-8 ti-facebook"></i>
                                                <a href="https://www.facebook.com/burleighoptom/">facebook.com/burleighoptom</a>
                                            </li>

                                            <li className="media mb-3">
                                                <i className="mr-3 display-8 ti-instagram"></i>
                                                <a href="https://www.instagram.com/burleighoptom/">instagram.com/burleighoptom</a>
                                            </li>

                                            <li className="media mb-3">
                                                <i className="mr-3 display-8 ti-time"></i>
                                                <TradingHours
                                                    monday="9am–5pm"
                                                    tuesday="9am–5pm"
                                                    wednesday="9am–5pm"
                                                    thursday="9am–5pm"
                                                    friday="9am–5pm"
                                                    saturday="9am-12pm" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="divider mt-3"></div>

                    <div className="footer-copyright py-3">
                        <div className="container">
                            <div
                                className="d-md-flex justify-content-between align-items-center py-3 text-center text-md-left">
                                <div className="copyright-text">&copy; {new Date().getFullYear()} <a href="index.html">Burleigh
                                    Optometrists</a>.
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <div>
                    {/* This was <a> */}
                    <button className="back-top btn btn-grad">
                        <i className="ti-angle-up"></i>
                    </button>
                </div>
            </>
        );
    }
}